<template>
  <div></div>
</template>

<script>
import { PORTAL_URL } from "@/config";
import { getLocalStorge } from "@/service/common";
export default {
  async mounted() {
    this.postMessage();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    async postMessage() {
      const logedUser = await getLocalStorge("logedUser");
      // console.log("logedUser", logedUser);
      window.parent.postMessage(
        {
          type: "post_portal_logedUser",
          logedUser,
        },
        PORTAL_URL
      );
      this.timer = setInterval(async () => {
        const logedUser = await getLocalStorge("logedUser");
        // console.log("logedUser", logedUser);
        window.parent.postMessage(
          {
            type: "post_portal_logedUser",
            logedUser,
          },
          PORTAL_URL
        );
      }, 3000);
    },
  },
};
</script>

<style>
</style>